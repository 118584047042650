// @flow

import React from 'react';

/**
 * Render image on normal DPI screen or 2x image on Retina
 */

type Props = {
  src: string, // URL of the _normal_ DPI image
  src2x?: string, // URL of the Retina DPI image
  alt?: string, // Image alt
  className?: string // Optional class name to be applied to the image
};

const ResponsiveImage = (props: Props) => {
  const { src, src2x, alt, className } = props;
  const scrSet = src + (src2x ? `, ${src2x} 2x` : '');
  return <img className={className} srcSet={scrSet} src={src} alt={alt} />;
};

ResponsiveImage.defaultProps = { alt: '' };

export default ResponsiveImage;
