import React from 'react';
import { Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { MARGIN } from '@latitude/core/utils/constants';
import { Link } from '@latitude/link';

const faqsGenoapay = {
  genoapay: [
    {
      groupName: 'About Genoapay',
      groupData: [
        {
          title: 'How does it work?',
          content: (
            <Text>
              Genoapay gives you the ability to convert eligible purchases into
              a 10 week instalment plan, so you can pay for what you need over
              time, with no interest, and no fees (except if a weekly payment is
              declined). Genoapay takes the first instalment when you purchase
              instore or online, and the remaining balance is processed weekly.
            </Text>
          )
        },
        {
          title: 'Who can use Genoapay?',
          content: (
            <Text>
              Anyone who's at least 18 years old, who holds a valid New Zealand
              driver's licence or passport, and who has a Visa or Mastercard
              debit or credit card.
            </Text>
          )
        }
      ]
    },
    {
      groupName: 'How do I use Genoapay?',
      groupData: [
        {
          title: "What's the catch",
          content: (
            <Text>
              The only time Genoapay ever charges a fee is if a weekly payment
              declines. On each occasion, a $10 late fee applies.
            </Text>
          )
        },
        {
          title: 'What can I buy on Genoapay?',
          content: (
            <Text>
              Eligible purchases between $20 and $1000*. Just look for a little
              Genoapay logo at the checkout.
            </Text>
          )
        },
        {
          title: 'Where can I use Genoapay?',
          content: (
            <Text>
              Genoapay is available at over 1,000 NZ businesses and can be used
              in store and online. Use the{' '}
              <Link href="https://www.genoapay.com/stores">store finder</Link>{' '}
              to find participating businesses in your area.
            </Text>
          )
        },
        {
          title: 'Can I place more than one order using Genoapay?',
          content: (
            <Text>
              You can place as many orders as you want, as long as they're
              within your approved Genoapay credit limit.
            </Text>
          )
        }
      ]
    }
  ]
};

export default faqsGenoapay;
