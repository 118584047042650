import React from 'react';
import PropTypes from 'prop-types';

import './_circled-character.scss';

const CircledCharacter = props => {
  const className = `circled-character ${  props.className || ''}`;

  return (
    <div className={className} style={props.style}>
      <div className="circled-character__character">
        {props.children}
      </div>
    </div>
  )
}

CircledCharacter.propTyes = {
  children:  PropTypes.node,
}

export default CircledCharacter;
